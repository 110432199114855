import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const RightArrowIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M26.5561 39L24.0306 36.4554L34.4278 25.8415L7.5 25.8415L7.5 22.1585L34.4278 22.1585L24.0306 11.5781L26.5561 9L41.25 24L26.5561 39Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default RightArrowIcon
