import { Box, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import FadeIn from 'react-fade-in'
import { getFundConfig } from '@flock/shared-ui'

import { OnboardingBackgroundProps } from './OnboardingBackground'
import Pristine from '../../../images/condition-images/pristine.webp'
import Great from '../../../images/condition-images/great.webp'
import Average from '../../../images/condition-images/average.webp'
import BelowAverage from '../../../images/condition-images/belowAverage.webp'
import NeedsReno from '../../../images/condition-images/needsReno3.webp'

const configs: { [key: string]: any } = {
  '5 - pristine': {
    src: Pristine,
  },
  '4 - great': {
    src: Great,
    bubbleText: `We make sure all of our homes are up to ${
      getFundConfig().companyName
    } Standards, which means great attention to ensuring the quality of homes that enter the fund.`,
    bubblePosition: 'top-right',
  },
  '3 - average': {
    src: Average,
  },
  '2 - below average': {
    src: BelowAverage,
  },
  '1 - requires complete renovation': {
    src: NeedsReno,
  },
}

type BackgroundImageProps = {
  src: string
  loaded: boolean
}

const BackgroundImage = (props: BackgroundImageProps) => {
  const { src, loaded } = props
  return (
    <Box
      position="absolute"
      height="100%"
      width={{
        xs: '100vw',
        md: '50vw',
      }}
      left="0px"
      zIndex={-3}
      sx={{
        transition: 'opacity 300ms ease',
        opacity: loaded ? 1 : 0,
        backgroundImage: `url("${src}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  )
}

const BlurredBackground = styled(Box)({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  backgroundColor: 'rgba(255, 255, 255, 0.75)',
  backdropFilter: 'blur(2px)',
  borderRadius: '12px',
})

const ConditionBackground = (props: OnboardingBackgroundProps) => {
  const { currentFormState } = props
  const { propertyCondition } = currentFormState

  const config = configs[propertyCondition] || configs['5 - pristine']
  const { bubbleText, bubblePosition } = config

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  let bubbleSx: any = {
    bottom: '124px',
    right: '116px',
  }

  switch (bubblePosition) {
    case 'top-left':
      bubbleSx = {
        top: '208px',
        right: '550px',
        transform: 'translateX(100%)',
      }
      break
    case 'top-right':
      bubbleSx = {
        top: '208px',
        right: '116px',
      }
      break
    case 'bottom-left':
      bubbleSx = {
        bottom: '124px',
        right: '550px',
        transform: 'translateX(100%)',
      }
      break
    case 'bottom-right':
      bubbleSx = {
        bottom: '124px',
        right: '116px',
      }
      break
    default:
      break
  }

  return (
    <>
      <BackgroundImage
        src={Pristine}
        loaded={propertyCondition?.includes('5')}
      />
      <BackgroundImage src={Great} loaded={propertyCondition?.includes('4')} />
      <BackgroundImage
        src={Average}
        loaded={propertyCondition?.includes('3')}
      />
      <BackgroundImage
        src={BelowAverage}
        loaded={propertyCondition?.includes('2')}
      />
      <BackgroundImage
        src={NeedsReno}
        loaded={propertyCondition?.includes('1')}
      />
      {!isTablet && bubbleText && (
        <FadeIn delay={2000}>
          <Box position="absolute" height="100%" width="50vw" left="0px">
            <Box width="100%" height="100%" position="relative">
              <BlurredBackground
                sx={{
                  maxWidth: '224px',
                  ...bubbleSx,
                }}
              >
                <Typography variant="p3">{bubbleText}</Typography>
              </BlurredBackground>
            </Box>
          </Box>
        </FadeIn>
      )}
      {isTablet && (
        <Box
          position="absolute"
          height="100%"
          width="100vw"
          left="0px"
          zIndex={-1}
          sx={{
            backgroundColor: 'gray1.main',
          }}
        />
      )}
    </>
  )
}

export default ConditionBackground
