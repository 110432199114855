/* eslint no-case-declarations: 0 */
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useForm, Controller, UseFormProps, useWatch } from 'react-hook-form'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  CircularProgress,
  Collapse,
  Grid,
  GridProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Fade from 'react-reveal/Fade'
import dayjs from 'dayjs'
import { PHONE_PATTERN } from '@flock/utils'

import {
  AnyInputConfig,
  CustomComponentProps,
  CustomInputProps,
  InputConfig,
  InputType,
  MultiformProps,
  SectionProps,
  SectionState,
  SectionStatus,
} from './InputTypes'
import {
  FormattedTextField,
  FormattedTextFieldProps,
} from '../FormattedTextField'
import { RadioSelect, RadioSelectProps } from '../RadioSelect'
import { Dropdown, DropdownProps } from '../Dropdown'
import { DatePicker, DatePickerProps } from '../DatePicker'
import {
  DateTimePickerComponent,
  DateTimePickerComponentProps,
} from '../DateTimePicker'
import { AddressTextField, AddressTextFieldProps } from '../AddressTextField'
import InputDecorator from '../InputDecorator/InputDecorator'
import { FileUpload, FileUploadProps } from '../FileUpload'
import { PercentageSlider, PercentageSliderProps } from '../PercentageSlider'
import { Checkbox, CheckboxProps } from '../Checkbox'
import { MultiCheckbox, MultiCheckboxProps } from '../MultiCheckbox'
import { CaretIcon } from '../icons/CaretIcon'

export type GridFormProps = {
  inputConfigs: AnyInputConfig[]
  ctaText?: string
  backText?: string
  loading?: boolean
  onSubmit: (result: any, reset?: () => void) => void
  onBack?: (result?: any) => void
  hideSubmit?: boolean
  hideBack?: boolean
  formProps?: Partial<UseFormProps>
  gridProps?: GridProps
  ctaBoxProps?: BoxProps
  ctaButtonProps?: ButtonProps
  onUpdated?: (values: any) => void
  onUpdatedFormatted?: (values: any) => void
  onSectionsUpdated?: (sectionState: SectionState[]) => void
  headerComponent?: React.ReactNode
  injectedFormValues?: { [key: string]: any } // flat object with (fieldName, Value) kv pairs to set in the form
  globalRadioProps?: Partial<RadioSelectProps>
}

const GridForm = (props: GridFormProps) => {
  const {
    inputConfigs,
    ctaText,
    backText,
    onSubmit,
    gridProps,
    onBack,
    hideSubmit,
    hideBack,
    formProps,
    ctaBoxProps,
    ctaButtonProps,
    onUpdated,
    onUpdatedFormatted,
    onSectionsUpdated,
    headerComponent,
    loading,
    injectedFormValues,
    globalRadioProps,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [hasVisibleErrors, setHasVisibleErrors] = useState(false)
  const [isCollapsedMap, setIsCollapsedMap] = useState<Map<any, boolean>>(
    () => new Map()
  )

  const currentYear = useMemo(() => new Date().getFullYear(), [])

  const getDefaultValues = (defaultValueInputConfigs: AnyInputConfig[]) => {
    let defaultValues: { [key: string]: any } = {}
    if (formProps?.defaultValues) {
      defaultValues = { ...defaultValues, ...formProps.defaultValues }
    }
    defaultValueInputConfigs.forEach((config) => {
      const { name, type, defaultValue, props: inputProps } = config
      switch (type) {
        case InputType.Text:
          defaultValues[name] = ''
          break
        case InputType.Dropdown:
        case InputType.RadioSelect:
          const { options } = inputProps as DropdownProps | RadioSelectProps
          if (defaultValue === '') {
            defaultValues[name] = undefined
          } else {
            defaultValues[name] = options[0].value
          }
          break
        case InputType.PercentageSlider:
          defaultValues[name] = 0
          break
        case InputType.Multiform:
          defaultValues[`${name}numForms`] = defaultValue || 1
          // This is a temporary fix that only goes down one layer of multiform. We need to refactor this to be recursive
          const multiformProps = inputProps as MultiformProps
          multiformProps.inputConfigs.forEach(
            (multiformInputConfig: AnyInputConfig) => {
              const { name: multiformInputName, type: multiformInputType } =
                multiformInputConfig
              if (multiformInputType === InputType.Multiform) {
                defaultValues[`${name}0${multiformInputName}numForms`] = 1
              }
            }
          )
          break
        case InputType.Checkbox:
          defaultValues[name] = defaultValue
          break
        case InputType.DateTimePicker:
          defaultValues[name] = defaultValue
          break
        default:
          break
      }

      // Explicitly set the default value from the input on the form
      if (defaultValue !== null && defaultValue !== undefined) {
        defaultValues[name] = defaultValue
      }

      if (type === InputType.Section) {
        const sectionProps = inputProps as SectionProps
        defaultValues = {
          ...defaultValues,
          ...getDefaultValues(sectionProps.inputConfigs),
        }
      }

      if (formProps?.defaultValues?.[name]) {
        if (type === InputType.Multiform) {
          const formDefaultValue = formProps.defaultValues[name] as any[]
          // Default value for multiform is an array
          // Decompose the array into constituent inputs to set the default input values
          defaultValues[`${name}numForms`] = formDefaultValue.length
          formDefaultValue.forEach(
            (obj: { [key: string]: any }, idx: number) => {
              Object.keys(obj).forEach((objKey: string) => {
                defaultValues[`${objKey}${idx}`] = obj[objKey]
              })
            }
          )
        } else if (formProps?.defaultValues?.[name]) {
          defaultValues[name] = formProps.defaultValues[name]
        }
      }
    })
    return defaultValues
  }

  // Set up default values
  const initialDefaultValues = useMemo(
    () => getDefaultValues(inputConfigs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formProps]
  )

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
    ...formProps,
    defaultValues: initialDefaultValues,
  })

  useEffect(() => {
    if (injectedFormValues) {
      Object.entries(injectedFormValues).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [injectedFormValues, setValue])

  const watchedFields = useWatch({ control })

  const processResultForInputConfigs = (
    result: any,
    inputConfigsToProcess: InputConfig<InputType>[],
    parentName: string
  ) => {
    let newResult = {
      ...result,
    }
    // Iterate through input configs and look for any multiform inputs
    inputConfigsToProcess.forEach((config: InputConfig<InputType>) => {
      const { type, name, renderIf } = config

      if (type === InputType.Section) {
        const sectionProps = config.props as SectionProps
        if (renderIf && !renderIf(watchedFields)) {
          return
        }
        const { inputConfigs: sectionInputConfigs } = sectionProps
        newResult = {
          ...processResultForInputConfigs(newResult, sectionInputConfigs, ''),
        }
      }

      if (type === InputType.Multiform) {
        if (renderIf && !renderIf(watchedFields)) {
          return
        }

        const multiformProps = config.props as MultiformProps
        const { inputConfigs: multiformInputConfigs } = multiformProps

        // we use parent name to keep track of nested multiforms. if it's not nested, parentName is empty
        // The number of forms generated is stored in the result object as the config's input name
        const numForms = result[`${parentName}${name}numForms`]

        const formResults = []
        // Iterate for the number of forms generated
        for (let i = 0; i < numForms; i += 1) {
          const resultObj: any = {}
          // For each input, extract the input from the result and put it into an object alongside
          // the other inputs in the same form
          for (let j = 0; j < multiformInputConfigs.length; j += 1) {
            const multiformInputConfig = multiformInputConfigs[j]
            const multiformInputName = multiformInputConfig.name
            // if there is a nested multiform, recurse down with the parentname parameter
            if (multiformInputConfig.type === InputType.Multiform) {
              const res = processResultForInputConfigs(
                result,
                multiformInputConfigs,
                `${name}${i}`
              )
              resultObj[multiformInputName] = res[multiformInputName]
            } else {
              resultObj[multiformInputName] =
                result[`${parentName}${multiformInputName}${i}`]
            }

            delete newResult[`${parentName}${multiformInputName}${i}`]
          }

          formResults.push(resultObj)
        }
        newResult[name] = formResults
        delete newResult[`${name}numForms`]
      }
    })
    return newResult
  }

  const processResult = (result: any) => {
    const newResult = processResultForInputConfigs(result, inputConfigs, '')
    onSubmit(newResult, reset)
  }

  // this function is run onBlur (for most components) to run the callback functions
  // passed in (to update any variables dependent on the state of the form)
  // and update the status of the sections (completed, incomplete, partial, etc.)
  const onValueChangeCallback = useCallback(
    (fields: any, formErrors: any) => {
      if (onUpdated) {
        onUpdated(fields)
      }
      if (onUpdatedFormatted) {
        onUpdatedFormatted(
          processResultForInputConfigs(fields, inputConfigs, '')
        )
      }
      if (onSectionsUpdated) {
        // Iterate through each section and determine their completion status
        const sectionStates = inputConfigs.reduce(
          (
            resultsArray: SectionState[],
            sectionConfig: InputConfig<InputType>
          ) => {
            if (sectionConfig.type === InputType.Section) {
              const sectionProps = sectionConfig.props as SectionProps
              const newResult = resultsArray.slice()

              if (sectionConfig.renderIf) {
                if (!sectionConfig.renderIf(fields)) {
                  return newResult
                }
              }

              let hasAllComplete = true
              let hasSomeComplete = false

              // if all fields in a section are not required, show PARTIAL section status. If even one is completed, show COMPLETED status
              if (
                sectionProps.inputConfigs.every(
                  (sectionInputConfig) => !sectionInputConfig.required
                )
              ) {
                hasSomeComplete = true
                if (
                  !sectionProps.inputConfigs.some(
                    (sectionInputConfig) =>
                      !['', undefined, null].includes(
                        fields[sectionInputConfig.name]
                      ) && !formErrors[sectionInputConfig.name]
                  )
                ) {
                  hasAllComplete = false
                }
              } else {
                sectionProps.inputConfigs.forEach((sectionInputConfig) => {
                  if (sectionInputConfig.required) {
                    // check if we should care about this required field since it may not be rendered
                    if (
                      sectionInputConfig.renderIf &&
                      !sectionInputConfig.renderIf(fields)
                    ) {
                      return
                    }
                    if (
                      !['', undefined, null].includes(
                        fields[sectionInputConfig.name]
                      ) &&
                      !formErrors[sectionInputConfig.name]
                    ) {
                      hasSomeComplete = true
                    } else {
                      hasAllComplete = false
                    }
                  } else if (
                    // If this is a non-required field that is not rendered, ignore
                    sectionInputConfig.renderIf &&
                    !sectionInputConfig.renderIf(fields)
                  ) {
                    return
                  }
                  if (formErrors[sectionInputConfig.name]) {
                    // If there's an error for a non-required field, this section is technically not complete
                    hasAllComplete = false
                  } else if (
                    !['', undefined, null].includes(
                      fields[sectionInputConfig.name]
                    )
                  ) {
                    // If there's some valid value for a non-required field, the section has some complete
                    hasSomeComplete = true
                  }
                })
              }

              if (hasAllComplete && hasSomeComplete) {
                newResult.push({
                  name: sectionConfig.name,
                  title: sectionProps.title,
                  status: SectionStatus.COMPLETE,
                })
              } else if (hasSomeComplete) {
                newResult.push({
                  name: sectionConfig.name,
                  title: sectionProps.title,
                  status: SectionStatus.PARTIAL,
                })
              } else {
                newResult.push({
                  name: sectionConfig.name,
                  title: sectionProps.title,
                  status: SectionStatus.INCOMPLETE,
                })
              }
              return newResult
            }
            return resultsArray
          },
          []
        )
        onSectionsUpdated(sectionStates)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputConfigs]
  )

  useEffect(() => {
    // for initial load for loading progress sidebar, e.g. in investor portal.
    onValueChangeCallback(watchedFields, errors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const flattenInputConfigs = (
    currentInputConfigs: InputConfig<InputType>[],
    propagateRenderIf?: (values: { [key: string]: any }) => boolean
  ) => {
    let flattenedInputConfigs: InputConfig<InputType>[] = []

    currentInputConfigs.forEach((currentConfig) => {
      if (currentConfig.type === InputType.Section) {
        const sectionProps = currentConfig.props as SectionProps
        // if an entire section has a renderIf, we must propagete it to its individual inputs.
        flattenedInputConfigs = flattenedInputConfigs.concat(
          flattenInputConfigs(sectionProps.inputConfigs, currentConfig.renderIf)
        )
      } else {
        const tempConfig = currentConfig
        // We only override the inidividal input renderIf's if the caller section has a renderIf and the input renderIf is unset
        if (propagateRenderIf && !currentConfig.renderIf) {
          tempConfig.renderIf = propagateRenderIf
        }
        flattenedInputConfigs.push(tempConfig)
      }
    })

    return flattenedInputConfigs
  }

  // This code (fieldNamesToWatchOnChange, watchedFieldsForOnChange, and the useEffect, etc.) is necessary
  // because for most other fields we run the callbacks and update the form completion state onBlur for efficiency purposes.
  // But for some components, onBlur is not supported, or there can be race conditions with the implementation
  // So we still need to watch these values whenever they change.
  const [fieldNamesToWatchOnChange, setFieldNamesToWatchOnChange] = useState<
    string[]
  >([])

  useEffect(() => {
    const flattenedInputConfigs = flattenInputConfigs(inputConfigs)
    let fieldsToWatch = flattenedInputConfigs.map((config) => {
      if (
        [
          InputType.Address,
          InputType.FileUpload,
          InputType.MultiCheckbox,
          InputType.Checkbox, // we don't want to include in onBlur due to unresponsive behavior (need to click outside), but in onchange causes race condition
          InputType.RadioSelect,
        ].includes(config.type)
      ) {
        return config.name
      }
      return ''
    })
    fieldsToWatch = fieldsToWatch.filter((field) => field !== '')
    setFieldNamesToWatchOnChange(fieldsToWatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputConfigs])

  const watchedFieldsForOnChange = useWatch({
    control,
    name: fieldNamesToWatchOnChange,
  })

  useEffect(() => {
    onValueChangeCallback(watchedFields, errors)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchedFieldsForOnChange), JSON.stringify(errors)])

  useEffect(() => {
    const flattenedInputConfigs = flattenInputConfigs(inputConfigs)
    const hasErrors = flattenedInputConfigs.some((targetConfig) => {
      const { name, renderIf } = targetConfig
      if (errors[name]) {
        if (!renderIf || renderIf(watchedFields)) {
          return true
        }
      }
      return false
    })
    setHasVisibleErrors(hasErrors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields, JSON.stringify(errors)])

  const renderInput: (
    config: InputConfig<InputType>,
    multiformParentIndex?: number
  ) => React.ReactNode = (
    config: InputConfig<InputType>,
    multiformParentIndex?: number
  ) => {
    const {
      name,
      type,
      inputDecoratorProps,
      inputDecoratorMobileProps,
      renderIf,
      gridItemProps,
      labelText,
      labelOptionalText,
      rules: customRules,
      required,
      props: inputProps,
      mobileProps,
      parentName,
    } = config

    const parentNameString = parentName || ''

    if (renderIf && !renderIf(watchedFields, multiformParentIndex)) {
      return null
    }
    const sharedProps = {
      'data-testid': name,
      name,
      error: !!errors[name],
    }

    const key = `${name} - ${type}`

    let renderedComponent = null

    const rules = {
      ...customRules,
      required,
    }

    const error = errors[name]
    // We make error message a blank space to ensure that the helperText
    // has a margin on the bottom so that the layout does not shift when
    // an error message does appear.
    let errorMessage = ' '
    if (error && error.type === 'required') {
      errorMessage = 'This field is required'
    } else if (error?.message) {
      errorMessage = error.message
    }

    let responsiveInputProps: any = { ...inputProps }
    if (isMobile && mobileProps) {
      responsiveInputProps = {
        ...inputProps,
        ...mobileProps,
      }
    }

    let decoratedInputProps = {}
    let responsiveInputDecoratorProps = { ...inputDecoratorProps }

    if (inputDecoratorProps) {
      decoratedInputProps = {
        helperText: '',
        sx: {
          ...(responsiveInputProps.sx || {}),
          paddingTop: 0,
        },
      }

      if (isMobile && inputDecoratorMobileProps) {
        responsiveInputDecoratorProps = {
          ...inputDecoratorProps,
          ...inputDecoratorMobileProps,
        }
      }
    }

    switch (type) {
      case InputType.Text:
        const textFieldProps = responsiveInputProps as FormattedTextFieldProps
        const { format } = textFieldProps

        // If we've provided an input decorator, error messages will be provided
        // at the bottom of the form
        switch (format) {
          case 'email':
            rules.pattern = /^\S+@\S+\.\S+$/
            if (error) {
              errorMessage = 'Please enter a valid email'
            }
            break
          case 'year':
            // Required:false will not override the validate function, https://github.com/react-hook-form/react-hook-form/issues/1781
            rules.validate = (value) =>
              (value > 1700 && value <= currentYear) || (!required && !value)
            if (error) {
              errorMessage = 'Please enter a valid year'
            }
            break
          case 'phone':
            rules.pattern = PHONE_PATTERN
            if (error) {
              errorMessage = 'Please enter a valid phone number'
            }
            break
          case 'fullName':
            rules.pattern = /^\w.*\s.*\w/
            if (error) {
              errorMessage = 'Please enter a first and last name'
            }
            break
          case 'cityState':
            rules.pattern = /^[^,]*,[^,]+$/g
            if (error) {
              errorMessage = 'Please enter City, State'
            }
            break
          case 'ssn':
            rules.pattern = /[0-9]{3}-[0-9]{2}-[0-9]{4}/g
            if (error) {
              errorMessage = 'Please enter a valid ssn'
            }
            break
          case 'ein':
            rules.pattern = /[0-9]{2}-[0-9]{7}/g
            if (error) {
              errorMessage = 'Please enter a valid ein'
            }
            break
          default:
            break
        }

        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...otherFieldProps } = field
              return (
                <FormattedTextField
                  size="small"
                  fullWidth
                  {...sharedProps}
                  {...textFieldProps}
                  {...otherFieldProps}
                  error={!!error}
                  helperText={error && errorMessage}
                  {...decoratedInputProps}
                  onBlur={() => {
                    otherFieldProps.onBlur()
                    onValueChangeCallback(watchedFields, errors)
                  }}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.RadioSelect:
        const radioProps = responsiveInputProps as RadioSelectProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...otherFieldProps } = field
              return (
                <RadioSelect
                  {...globalRadioProps}
                  {...sharedProps}
                  {...radioProps}
                  {...otherFieldProps}
                  error={!!error}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.Dropdown:
        const dropdownProps = responsiveInputProps as DropdownProps

        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...otherFieldProps } = field
              return (
                <Dropdown
                  size="small"
                  {...sharedProps}
                  {...dropdownProps}
                  {...otherFieldProps}
                  error={!!error}
                  helperText={error && errorMessage}
                  {...decoratedInputProps}
                  onBlur={() => {
                    otherFieldProps.onBlur()
                    onValueChangeCallback(watchedFields, errors)
                  }}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.DatePicker:
        const datePickerProps = responsiveInputProps as DatePickerProps

        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...otherFieldProps } = field
              return (
                <DatePicker
                  size="small"
                  fullWidth
                  {...sharedProps}
                  {...datePickerProps}
                  {...otherFieldProps}
                  error={!!error}
                  helperText={error && errorMessage}
                  {...decoratedInputProps}
                  onBlur={() => {
                    otherFieldProps.onBlur()
                    onValueChangeCallback(watchedFields, errors)
                  }}
                />
              )
            }}
            control={control}
            name={name}
            rules={{
              ...rules,
              validate: (value) =>
                (!required && !value) ||
                dayjs(value).isValid() ||
                'Please enter a valid date.',
            }}
          />
        )
        break
      case InputType.DateTimePicker:
        const dateTimePickerProps =
          responsiveInputProps as DateTimePickerComponentProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { ref, ...otherFieldProps } = field
              return (
                <DateTimePickerComponent
                  size="small"
                  fullWidth
                  {...sharedProps}
                  {...dateTimePickerProps}
                  {...otherFieldProps}
                  error={!!error}
                  helperText={error && errorMessage}
                  {...decoratedInputProps}
                  onBlur={() => {
                    otherFieldProps.onBlur()
                    onValueChangeCallback(watchedFields, errors)
                  }}
                />
              )
            }}
            control={control}
            name={name}
            rules={{
              ...rules,
              validate: (value) =>
                (!required && !value) || // if not required we return true for validate
                dayjs(value).isValid() ||
                'Please enter a valid date.',
            }}
          />
        )
        break
      case InputType.Address:
        const addressProps = responsiveInputProps as AddressTextFieldProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { value, ref, ...otherFieldProps } = field
              return (
                <AddressTextField
                  size="small"
                  fullWidth
                  {...sharedProps}
                  {...addressProps}
                  {...otherFieldProps}
                  error={!!error}
                  helperText={error && errorMessage}
                  {...decoratedInputProps}
                  value={value}
                  onBlur={() => {
                    otherFieldProps.onBlur()
                  }}
                />
              )
            }}
            control={control}
            name={name}
            rules={{
              ...rules,
              validate: (value) =>
                (!required && !value) ||
                (!!value && !!value.streetAddress && !!value.city),
            }}
          />
        )
        break
      case InputType.Multiform:
        const multiformProps = config.props as MultiformProps
        const {
          title: multiStepperTitle,
          inputConfigs: multiformInputConfigs,
          minForms,
          maxForms,
          incrementText,
          decrementText,
          horizontal,
          itemContainerProps,
        } = multiformProps

        const numForms =
          watchedFields[`${parentNameString}${name}numForms`] || 1

        const renderedForms = []

        const gridItemColumns = horizontal ? 1 : 12

        for (let i = 0; i < numForms; i += 1) {
          renderedForms.push(
            <Grid
              // todo: sort this mess out. this is a hack to get the grid item to be full width
              // when horizontal is true (lead valuations)
              item={horizontal ? true : undefined}
              container={horizontal ? undefined : true}
              xs={gridItemColumns}
              key={`${name}${i}`}
              spacing={3}
              {...gridItemProps}
            >
              <Grid item xs={12}>
                <Typography variant="p2">
                  {`${multiStepperTitle} ${numForms > 1 ? i + 1 : ''}`}
                </Typography>
              </Grid>
              {multiformInputConfigs.map(
                (multiformConfig: InputConfig<InputType>) => {
                  const isConfigMultiform =
                    multiformConfig.type === InputType.Multiform

                  return renderInput(
                    {
                      ...multiformConfig,
                      name: isConfigMultiform
                        ? `${parentNameString}${multiformConfig.name}`
                        : `${parentNameString}${multiformConfig.name}${i}`,
                      parentName: isConfigMultiform ? `${name}${i}` : '',
                    },
                    i
                  )
                }
              )}
            </Grid>
          )
        }
        return (
          <Grid
            container
            item
            xs={12}
            gap={3}
            {...itemContainerProps}
            data-type="multiform"
            data-testid={name}
          >
            {horizontal ? (
              <Grid item xs={12}>
                <Grid
                  container
                  wrap="nowrap"
                  sx={{ overflowX: 'scroll', width: '800px' }}
                  columns={Math.min(numForms, 3)}
                  gap={3}
                >
                  <>{renderedForms}</>
                </Grid>
              </Grid>
            ) : (
              <>{renderedForms}</>
            )}

            <Box display="flex" flexDirection="column" gap="16px" width="100%">
              {numForms > minForms && (
                <Button
                  fullWidth
                  variant="secondary"
                  size="smallForm"
                  data-testid={`${name}-remove-form`}
                  onClick={() => {
                    setValue(`${parentNameString}${name}numForms`, numForms - 1)
                    onValueChangeCallback(watchedFields, errors)
                  }}
                >
                  {decrementText}
                </Button>
              )}
              {(!maxForms || (maxForms && numForms < maxForms)) && (
                <Button
                  fullWidth
                  variant="secondary"
                  size="smallForm"
                  data-testid={`${name}-add-form`}
                  onClick={() => {
                    setValue(`${parentNameString}${name}numForms`, numForms + 1)

                    // if there is a child multiform, set default numforms
                    multiformInputConfigs.forEach(
                      (multiformConfig: InputConfig<InputType>) => {
                        if (multiformConfig.type === InputType.Multiform) {
                          setValue(
                            `${parentNameString}${name}${numForms}${multiformConfig.name}numForms`,
                            1
                          )
                        }
                      }
                    )
                    onValueChangeCallback(watchedFields, errors)
                  }}
                >
                  {incrementText}
                </Button>
              )}
            </Box>
          </Grid>
        )

      case InputType.Section:
        const sectionProps = config.props as SectionProps
        const {
          title,
          sectionGridProps,
          collapsible,
          collapsibleHorizontal,
          inputConfigs: sectionInputConfigs,
        } = sectionProps
        return (
          <Grid item xs={12} {...gridItemProps}>
            <Grid
              container
              id={`${name}-section`}
              spacing={3}
              {...sectionGridProps}
            >
              <Grid item xs={12}>
                <Typography variant="h4">
                  {title}
                  {collapsible && (
                    <>
                      {isCollapsedMap.get(name) ? (
                        <Box
                          onClick={() =>
                            setIsCollapsedMap(
                              (prevOpenMap) =>
                                // @ts-ignore
                                new Map([...prevOpenMap, [name, false]])
                            )
                          }
                          display="inline-block"
                          sx={{ verticalAlign: 'text-bottom' }}
                        >
                          <CaretIcon />
                        </Box>
                      ) : (
                        <Box
                          onClick={() =>
                            setIsCollapsedMap(
                              (prevOpenMap) =>
                                // @ts-ignore
                                new Map([...prevOpenMap, [name, true]])
                            )
                          }
                          sx={{
                            verticalAlign: 'text-bottom',
                            transform: 'rotate(180deg)',
                          }}
                          display="inline-block"
                        >
                          <CaretIcon />
                        </Box>
                      )}
                    </>
                  )}
                </Typography>
              </Grid>

              {collapsible ? (
                <Collapse
                  sx={{ width: '100%', pl: '24px' }}
                  in={!isCollapsedMap.get(name)}
                >
                  {collapsibleHorizontal ? (
                    <Box display="flex" flexDirection="row" gap="8px">
                      {sectionInputConfigs.map(
                        (sectionInputConfig: InputConfig<InputType>) =>
                          renderInput(sectionInputConfig)
                      )}
                    </Box>
                  ) : (
                    <Grid container spacing={3}>
                      {sectionInputConfigs.map(
                        (sectionInputConfig: InputConfig<InputType>) =>
                          renderInput(sectionInputConfig)
                      )}
                    </Grid>
                  )}
                </Collapse>
              ) : (
                sectionInputConfigs.map(
                  (sectionInputConfig: InputConfig<InputType>) =>
                    renderInput(sectionInputConfig)
                )
              )}
            </Grid>
          </Grid>
        )
      case InputType.FileUpload:
        const fileUploadProps = responsiveInputProps as FileUploadProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { value, ref, ...otherFieldProps } = field
              return (
                <FileUpload
                  {...sharedProps}
                  {...fileUploadProps}
                  {...otherFieldProps}
                  defaultFiles={value}
                  error={!!error}
                  helperText={error && errorMessage}
                  {...decoratedInputProps}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.Checkbox:
        const checkboxProps = responsiveInputProps as CheckboxProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { value, ref, ...otherFieldProps } = field
              return (
                <Checkbox
                  {...sharedProps}
                  {...checkboxProps}
                  {...otherFieldProps}
                  checked={value}
                  error={!!error}
                  {...decoratedInputProps}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.MultiCheckbox:
        const multiCheckboxProps = responsiveInputProps as MultiCheckboxProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { value, ref, ...otherFieldProps } = field
              return (
                <MultiCheckbox
                  {...sharedProps}
                  {...multiCheckboxProps}
                  {...otherFieldProps}
                  error={!!error} // error state maintained internally in component -> see component comments
                  {...decoratedInputProps}
                  required={required}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.PercentageSlider:
        const percentageSliderProps =
          responsiveInputProps as PercentageSliderProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { value, ref, ...otherFieldProps } = field
              return (
                <PercentageSlider
                  {...sharedProps}
                  {...percentageSliderProps}
                  defaultValue={value}
                  {...otherFieldProps}
                  {...decoratedInputProps}
                  onBlur={() => {
                    otherFieldProps.onBlur()
                    onValueChangeCallback(watchedFields, errors)
                  }}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.CustomInput:
        const customInputProps = config.props as CustomInputProps
        const { component: CustomInput, componentProps } = customInputProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              const { value, onChange, onBlur: controllerOnBlur } = field
              return (
                <CustomInput
                  {...sharedProps}
                  {...componentProps}
                  value={value}
                  error={!!error}
                  helperText={error && errorMessage}
                  onChange={onChange}
                  onBlur={() => {
                    controllerOnBlur()
                    onValueChangeCallback(watchedFields, errors)
                  }}
                />
              )
            }}
            control={control}
            name={name}
            rules={rules}
          />
        )
        break
      case InputType.CustomComponent:
        const customComponentProps = config.props as CustomComponentProps
        renderedComponent = customComponentProps.component
        break
      default:
        break
    }

    if (inputDecoratorProps) {
      return (
        <Grid key={key} item xs={12} {...gridItemProps}>
          <Box display="flex" flexDirection="column" gap="24px">
            {labelText && (
              <Typography variant="h4">
                {labelText}{' '}
                <Box component="span" color="gray5.main">
                  {labelOptionalText}
                </Box>
              </Typography>
            )}
            <InputDecorator error={error} {...responsiveInputDecoratorProps}>
              {renderedComponent}
            </InputDecorator>
          </Box>
        </Grid>
      )
    } else {
      return (
        <Grid key={key} item xs={12} {...gridItemProps}>
          <Box display="flex" flexDirection="column" gap="24px">
            {labelText && (
              <Typography variant="h4" color="gray8.main">
                {labelText}{' '}
                <Box component="span" color="gray5.main">
                  {labelOptionalText}
                </Box>
              </Typography>
            )}
            {renderedComponent}
          </Box>
        </Grid>
      )
    }
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="space-between"
      gap="24px"
    >
      {headerComponent}
      <Fade bottom distance="16px" enter exit duration={350}>
        <Box>
          <Grid container item xs={12} {...gridProps}>
            {inputConfigs.map((config) => renderInput(config))}
          </Grid>
        </Box>
      </Fade>

      <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
        {hasVisibleErrors && (
          <Typography variant="p3" color="error">
            Fix the issues highlighted in red before moving forward.
          </Typography>
        )}
        <Box
          display="flex"
          width="100%"
          justifyContent={onBack ? 'space-between' : 'flex-end'}
          {...ctaBoxProps}
        >
          {onBack && !hideBack ? (
            <Box>
              <Button
                variant="secondary"
                size="smallForm"
                disabled={loading}
                onClick={() => {
                  onBack(watchedFields)
                }}
                {...ctaButtonProps}
              >
                {backText || 'Back'}
              </Button>
            </Box>
          ) : (
            <Box />
          )}
          {!hideSubmit && (
            <Box justifySelf="flex-end">
              <Button
                variant="primary"
                size="smallForm"
                disabled={loading}
                onClick={handleSubmit(processResult)}
                data-testid="submit-form-btn"
                sx={{
                  width: '104px',
                }}
                {...ctaButtonProps}
              >
                {loading ? <CircularProgress size="24px" /> : ctaText || 'Next'}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default GridForm

GridForm.defaultProps = {
  ctaText: 'Next',
  backText: 'Back',
  onBack: undefined,
  formProps: {},
  gridProps: {},
  ctaBoxProps: {},
  onUpdated: undefined,
  onUpdatedFormatted: undefined,
  onSectionsUpdated: undefined,
  headerComponent: null,
  loading: false,
  hideBack: false,
  hideSubmit: false,
  injectedFormValues: null,
  globalRadioProps: {},
}
