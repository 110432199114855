import {
  ProgressIndicator,
  TrackedIconButton,
  TrackedLink,
  useFundConfig,
} from '@flock/shared-ui'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { navigateToHomepage } from '../utils'

type Status = 'complete' | 'current' | 'next'
export type ProgressType =
  | 'address'
  | 'condition'
  | 'financials'
  | 'profile'
  | 'complete'

export type WebflowHeaderVariant = 'addressFirst' | 'contactFirst' | 'dst'

type WebflowHeaderProps = {
  variant: 'addressFirst' | 'contactFirst' | 'dst'
  currentProgress:
    | 'address'
    | 'condition'
    | 'financials'
    | 'profile'
    | 'complete'
    | 'details'
}

const WebflowHeader = (props: WebflowHeaderProps) => {
  const { variant, currentProgress } = props

  const { logo: Logo, logoWidthToHeight } = useFundConfig()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <Grid container columns={4} spacing={2}>
        <Grid item xs={1}>
          <TrackedLink
            aria-label="Homepage"
            sx={{
              pointerEvents: 'all',
              '&:hover': {
                backgroundColor: 'unset',
              },
            }}
            onClick={navigateToHomepage}
          >
            <Logo width="186px" height={186 / logoWidthToHeight} color="logo" />
          </TrackedLink>
        </Grid>
      </Grid>
    )
  }

  let address: Status = 'current'
  let condition: Status = 'next'
  let financials: Status = 'next'
  let profile: Status = 'next'
  let details: Status = 'next'
  let complete: Status = 'next'

  if (variant === 'contactFirst') {
    switch (currentProgress) {
      case 'financials':
        profile = 'complete'
        address = 'complete'
        condition = 'complete'
        financials = 'current'
        break
      case 'condition':
        profile = 'complete'
        address = 'complete'
        condition = 'current'
        financials = 'next'
        break
      case 'address':
        profile = 'complete'
        address = 'current'
        condition = 'next'
        financials = 'next'
        break
      case 'profile':
        profile = 'current'
        address = 'next'
        condition = 'next'
        financials = 'next'
        break
      case 'complete':
        profile = 'complete'
        address = 'complete'
        condition = 'complete'
        financials = 'complete'
        break
      default:
        break
    }

    return (
      <Grid container columns={isTablet ? 6 : 5} spacing={4}>
        {isTablet && (
          <Grid item sm={1}>
            <TrackedIconButton
              disableRipple
              aria-label="Homepage"
              sx={{
                '&:hover': {
                  backgroundColor: 'unset',
                },
              }}
              onClick={navigateToHomepage}
            >
              <Logo width="149px" height={149 / logoWidthToHeight} />
            </TrackedIconButton>
          </Grid>
        )}
        <Grid item sm={address === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={address}
            text="Address"
            noWrap
          />
        </Grid>
        <Grid item sm={condition === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={condition}
            text="Condition"
            noWrap
          />
        </Grid>
        <Grid item sm={financials === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={financials}
            text="Financials"
            noWrap
          />
        </Grid>
        {currentProgress === 'complete' && (
          <Grid item sm={1}>
            <ProgressIndicator
              width="100%"
              status="current"
              text="Complete"
              noWrap
            />
          </Grid>
        )}
      </Grid>
    )
  } else if (variant === 'addressFirst') {
    switch (currentProgress) {
      case 'profile':
        address = 'complete'
        profile = 'current'
        condition = 'next'
        financials = 'next'
        break
      case 'financials':
        address = 'complete'
        profile = 'complete'
        condition = 'complete'
        financials = 'current'
        break
      case 'condition':
        address = 'complete'
        profile = 'complete'
        condition = 'current'
        financials = 'next'
        break
      case 'address':
        address = 'current'
        profile = 'next'
        condition = 'next'
        financials = 'next'
        break
      case 'complete':
        profile = 'complete'
        address = 'complete'
        condition = 'complete'
        financials = 'complete'
        break
      default:
        break
    }

    return (
      <Grid container columns={5} spacing={4}>
        <Grid item sm={address === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={address}
            text="Address"
            noWrap
          />
        </Grid>
        <Grid item sm={condition === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={condition}
            text="Condition"
            noWrap
          />
        </Grid>
        <Grid item sm={financials === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={financials}
            text="Financials"
            noWrap
          />
        </Grid>

        {currentProgress === 'complete' && (
          <Grid item sm={1}>
            <ProgressIndicator
              width="100%"
              status="current"
              text="Complete"
              noWrap
            />
          </Grid>
        )}
      </Grid>
    )
  } else if (variant === 'dst') {
    switch (currentProgress) {
      case 'profile':
        complete = 'next'
        details = 'next'
        address = 'next'
        profile = 'current'
        break
      case 'address':
        complete = 'next'
        details = 'next'
        address = 'current'
        profile = 'complete'
        break
      case 'details':
        complete = 'next'
        details = 'current'
        address = 'complete'
        profile = 'complete'
        break
      case 'complete':
        complete = 'current'
        details = 'complete'
        address = 'complete'
        profile = 'complete'
        break
      default:
        break
    }

    return (
      <Grid container columns={isTablet ? 6 : 5} spacing={4}>
        {isTablet && (
          <Grid item sm={1}>
            <TrackedIconButton
              disableRipple
              aria-label="Homepage"
              sx={{
                '&:hover': {
                  backgroundColor: 'unset',
                },
              }}
              onClick={navigateToHomepage}
            >
              <Logo width="149px" height={149 / logoWidthToHeight} />
            </TrackedIconButton>
          </Grid>
        )}
        <Grid item sm={profile === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={profile}
            text="Profile"
            noWrap
          />
        </Grid>
        <Grid item sm={address === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={address}
            text="Address"
            noWrap
          />
        </Grid>
        <Grid item sm={details === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={details}
            text="Details"
            noWrap
          />
        </Grid>
        <Grid item sm={complete === 'current' ? 3 : 1}>
          <ProgressIndicator
            width="100%"
            status={complete}
            text="Complete"
            noWrap
          />
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default WebflowHeader
