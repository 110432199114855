import { styled, TextField } from '@mui/material'
import { Autocomplete, useLoadScript } from '@react-google-maps/api'
import React, { useState, useEffect } from 'react'
import ActionTextField, {
  ActionTextFieldProps,
} from '../ActionTextField/ActionTextField'

const libraries = ['places'] as 'places'[]

const StyledAutocomplete = styled(Autocomplete)({})

export type AddressData = {
  formattedAddress: string
  streetAddress: string
  streetNumber: string
  city: string
  state: string
  zipcode: string
  county: string
  lat: number
  lng: number
}

export type AddressTextFieldProps = Omit<ActionTextFieldProps, 'onChange'> & {
  googleMapsApiKey: string
  onChange: (addressData: AddressData | null) => void
  onSubmit?: () => void
  'data-testid'?: string
}

const AddressTextField = (props: AddressTextFieldProps) => {
  const {
    onChange,
    googleMapsApiKey,
    onSubmit,
    onBlur,
    value,
    'data-testid': dataTestId,
    ...actionTextFieldProps
  } = props
  const [autocomplete, setAutocomplete] = useState<any>()
  const [showError, setShowError] = useState(false)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey:
      googleMapsApiKey || process.env.GATSBY_GOOGLE_MAPS_API_KEY || '',
    libraries,
  })

  const onAutocompleteLoad = (ac: any) => {
    setAutocomplete(ac)
  }

  useEffect(() => {
    // this is if the encompassing form calls reset() and resets the form value,
    // we want to clear the actual displayed value as well.
    if (!value) {
      const input = document.querySelector(
        `input[name="${actionTextFieldProps.name}"]`
      )
      // if not part of a form, the input would be undefined so we need this check
      if (input) {
        ;(input as HTMLInputElement).value = ''
      }
    }
    // this block is for prefilling on initial render in gridform
    // if we prefill a value into the input, we want them to click the input manually afterwards
    // to make sure a valid autocomplete result is selected
    // this is ensured since value is a string, but the validate rule in gridform requires AddressData structure
    if (value) {
      const input = document.querySelector(
        `input[name="${actionTextFieldProps.name}"]`
      )
      if (input) {
        // value can be a string (from the prefill), or it can be the json addressdata object
        if (typeof value === 'string') {
          ;(input as HTMLInputElement).value = value
        } else {
          // if value is addressdata, this is to stop it from displaying [object Object]
          // but it should not change the actual form data state
          ;(input as HTMLInputElement).value = (
            value as AddressData
          ).formattedAddress
        }
      }
    }
  }, [value, actionTextFieldProps.name, isLoaded])

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace()

    if (!place?.address_components) {
      onChange(null)
      setShowError(true)
      return
    }

    let city
    let zipcode
    let state
    let county
    let streetNumber
    let streetAddress
    let country

    for (let i = 0; i < place.address_components.length; i += 1) {
      // https://googlemaps.github.io/google-maps-services-java/v0.1.3/javadoc/com/google/maps/model/AddressType.html
      const currentComponent = place.address_components[i]

      if (currentComponent.types.indexOf('locality') !== -1) {
        city = currentComponent.long_name
      }
      if (currentComponent.types.indexOf('postal_code') !== -1) {
        zipcode = currentComponent.long_name
      }
      if (
        currentComponent.types.indexOf('administrative_area_level_1') !== -1
      ) {
        state = currentComponent.short_name
      }
      if (
        currentComponent.types.indexOf('administrative_area_level_2') !== -1
      ) {
        county = currentComponent.long_name
      }
      if (currentComponent.types.indexOf('street_number') !== -1) {
        streetNumber = currentComponent.long_name
      }
      if (currentComponent.types.indexOf('route') !== -1) {
        streetAddress = currentComponent.short_name
      }
      if (currentComponent.types.indexOf('country') !== -1) {
        country = currentComponent.short_name
      }
    }
    if (country === 'US' && /\d/.test(place.name)) {
      const addressData = {
        streetAddress,
        formattedAddress: place.formatted_address,
        city,
        zipcode,
        state,
        county,
        streetNumber,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      onChange(addressData as AddressData)
      setShowError(false)
    } else {
      setShowError(true)
      onChange(null)
    }
    if (onBlur) {
      // @ts-ignore
      onBlur()
    }
  }

  return (
    <>
      {!isLoaded ? (
        <ActionTextField
          key="placeholder-field"
          {...actionTextFieldProps}
          onSubmit={() => {}}
        />
      ) : (
        <StyledAutocomplete
          onLoad={onAutocompleteLoad}
          onPlaceChanged={onPlaceChanged}
          sx={{
            '& .pac-item': {
              width: '30rem',
            },
          }}
        >
          {onSubmit ? (
            <ActionTextField
              {...actionTextFieldProps}
              onSubmit={onSubmit}
              error={showError}
              helperText={
                showError
                  ? 'Please select a residential address in the US from the suggested results.'
                  : actionTextFieldProps.helperText ?? ' '
              }
            />
          ) : (
            <TextField
              placeholder="Enter your property address here"
              {...actionTextFieldProps}
              inputProps={{
                ...actionTextFieldProps.inputProps,
                'data-testid': dataTestId,
                'data-type': 'address',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onSubmit={onSubmit}
              error={actionTextFieldProps.error || showError}
              helperText={
                actionTextFieldProps.error || showError
                  ? 'Please select a residential address in the US from the suggested results.'
                  : actionTextFieldProps.helperText ?? ' '
              }
            />
          )}
        </StyledAutocomplete>
      )}
    </>
  )
}

export default AddressTextField
